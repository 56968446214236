'use client';

import GlobalStyle from '@legacy/common/components/GlobalStyle';
import { FoodlesThemeProvider } from '@legacy-components/theming';
import theme from '@legacy/theme/theme';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ReactNode } from 'react';
const ThemeProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  return <FoodlesThemeProvider data-sentry-element="FoodlesThemeProvider" data-sentry-component="ThemeProvider" data-sentry-source-file="ThemeProvider.tsx">
      <MuiThemeProvider theme={theme} data-sentry-element="MuiThemeProvider" data-sentry-source-file="ThemeProvider.tsx">
        <GlobalStyle data-sentry-element="GlobalStyle" data-sentry-source-file="ThemeProvider.tsx" />
        {children}
      </MuiThemeProvider>
    </FoodlesThemeProvider>;
};
export default ThemeProvider;