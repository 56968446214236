import styled from 'styled-components';

import { Color } from '@legacy-components/theme';
import {
  Size,
  colorFromTheme,
  radiusFromTheme,
} from '@legacy-components/theme/helper';

import WrappedToastContainer, {
  Props as WrappedToastContainerProps,
} from './WrappedToastContainer';

export const ThemedToastContainer = styled(
  WrappedToastContainer,
)<WrappedToastContainerProps>`

  .Toastify__progress-bar--wrp {
    display: none;
  }

  .Toastify__toast-container {
    padding: 0;

    > .Toastify__toast:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  .Toastify__toast {
    min-height: auto;
    height: auto;
    padding: 1em 1.5em;
    margin: 0;
    display: flex;
    align-items: center;

    border-radius: ${radiusFromTheme(Size.MEDIUM)};
    box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.12);
    color: ${colorFromTheme(Color.LIGHT)};
    background-color: ${colorFromTheme(Color.PRIMARY)};
  }

  .Toastify__toast--error {
    color: ${colorFromTheme(Color.LIGHT)};
    background-color: ${colorFromTheme(Color.DANGER)};
  }

  .Toastify__toast--warning {
    color: ${colorFromTheme(Color.LIGHT)};
    background-color: ${colorFromTheme(Color.POULTRY)};
  }

  .Toastify__toast--success {
    color: ${colorFromTheme(Color.LIGHT)};
    background-color: ${colorFromTheme(Color.SUCCESS)};
  }

  .Toastify__toast-body {
    height: fit-content;
    min-height: fit-content;
    margin: 0;
    padding: 0;
  }

  .Toastify__progress-bar {
  }
`;

export const CloseButtonContainer = styled.div`
  padding: 0.5em;
`;
