/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ReactElement } from 'react';
import { TypeOptions } from 'react-toastify';
import Symbol from '@legacy-components/Symbol';
import { Color } from '@legacy-components/theme';
import { CloseButtonContainer } from './styledComponents';
export interface Props {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  closeToast?: Function;
  type?: TypeOptions;
}
const colorByType: { [type in TypeOptions]: Color } = {
  default: Color.LIGHT,
  info: Color.LIGHT,
  error: Color.LIGHT,
  success: Color.LIGHT,
  warning: Color.LIGHT
};
const CloseButton = ({
  closeToast,
  type
}: Props): ReactElement =>
// eslint-disable-next-line @typescript-eslint/no-unsafe-return
<CloseButtonContainer role="button" onClick={() => closeToast?.()} data-sentry-element="CloseButtonContainer" data-sentry-component="CloseButton" data-sentry-source-file="CloseButton.tsx">
    <Symbol symbolKey="control/cross" scale={0.75} color={colorByType[type!]} data-sentry-element="Symbol" data-sentry-source-file="CloseButton.tsx" />
  </CloseButtonContainer>;
export default CloseButton;