'use client';

import { ReactNode, useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
const IntercomProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  useEffect(() => {
    const intercomApiKey = process.env.NEXT_PUBLIC_INTERCOM_API_KEY ?? '';
    Intercom({
      app_id: intercomApiKey
    });
    window.intercomSettings = {
      app_id: intercomApiKey,
      alignment: 'right',
      horizontal_padding: 50,
      vertical_padding: 30
    };
  }, []);
  return <>{children}</>;
};
export default IntercomProvider;