
import theme from '../../theme';
import { white, black } from '../colors';
import { Theme } from '../../theme';


export const BaseTheme: Theme = {
  ...theme,
  dark: false,
  roundness: 4,
  colors: {
    surface: white,
    text: black,
    background: '#000000',
    onSurface: '#000000',
  },
  animation: {
    scale: 1.0,
  },
};
