'use client';

import { ReactNode } from 'react';
import { MessageFormatElement, IntlProvider as ReactIntlProvider } from 'react-intl';
import enTranslations from '@assets/compiled-lang/en.json';
import frTranslations from '@assets/compiled-lang/fr.json';
import legacyFr from '@translations/customer-app/fr.json';
import legacyEn from '@translations/customer-app/en.json';
interface Props {
  children: ReactNode;
  locale: string;
}
const messages: Record<string, Record<string, MessageFormatElement[]>> = {
  fr: {
    ...frTranslations,
    ...legacyFr
  },
  en: {
    ...enTranslations,
    ...legacyEn
  }
};
const IntlProvider = ({
  children,
  locale
}: Props) => {
  return <ReactIntlProvider locale={locale} messages={messages[locale]} data-sentry-element="ReactIntlProvider" data-sentry-component="IntlProvider" data-sentry-source-file="index.tsx">
      {children}
    </ReactIntlProvider>;
};
export default IntlProvider;