import browser from 'browser-detect';

import { Nullable } from '@app-lib/common/typings';

import config from '../config';

export const { name: detectedBrowser } = config.isBrowser
  ? browser()
  : { name: null };

export enum Browser {
  Opera = 'opera',
  Firefox = 'firefox',
  Chrome = 'chrome',
  Safari = 'safari',
  InternetExplorer = 'ie',
  Edge = 'edge',
}

const SCROLL_SMOOTH_UNSUPPORTED_BROWSERS = [
  Browser.Safari,
  Browser.InternetExplorer,
];

export function hasScrollSmoothSupport(): boolean {
  return (
    !detectedBrowser ||
    !SCROLL_SMOOTH_UNSUPPORTED_BROWSERS.includes(detectedBrowser as Browser)
  );
}

export function scrollSmooth(hash: string): void {
  document.querySelector(`#${hash}`)?.scrollIntoView({ behavior: 'smooth' });

  setTimeout(() => {
    window.location.hash = hash;
  }, 100);
}

export interface OpenWindowSafelyOptions {
  openOnCurrentWindow?: boolean;
}

export function openWindowSafely(
  url: string,
  { openOnCurrentWindow = false }: OpenWindowSafelyOptions = {},
): void {
  const otherWindow: Nullable<WindowProxy> = openOnCurrentWindow
    ? window
    : window.open();
  if (otherWindow?.opener) {
    otherWindow.opener = null;
  }
  if (otherWindow?.location) {
    otherWindow.location.assign(url);
  }
}

export function deleteCookie(name: string): void {
  document.cookie = `${name}=;domain=${window.location.hostname};path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC`;
}

export function getCookie(name: string): Nullable<string> {
  if (typeof window === 'undefined') {
    return null;
  }
  const match = new RegExp(`(^| )${name}=([^;]+)`).exec(document.cookie);
  if (match) {
    return match[2];
  }
  return null;
}
