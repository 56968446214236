import React, { ReactElement } from 'react';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
export interface Props extends ToastContainerProps {
  className?: string;
}
const WrappedToastContainer = ({
  className,
  ...rest
}: Props): ReactElement => <div className={className} data-sentry-component="WrappedToastContainer" data-sentry-source-file="WrappedToastContainer.tsx">
    <ToastContainer {...rest} data-sentry-element="ToastContainer" data-sentry-source-file="WrappedToastContainer.tsx" />
  </div>;
export default WrappedToastContainer;