import { ReactElement, useState, PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';
import { DefaultTheme } from './themes/default';
import { Theme } from '../theme';
export type FoodlesTheme = Theme & {
  colors: {
    primaryLight?: string;
    primaryAlt?: string;
    primaryContrastText?: string;
    primaryLightContrastText?: string;
    accentLight?: string;
    accentAlt?: string;
    accentContrastText?: string;
    accentLightContrastText?: string;
  };
};
export const FoodlesThemeProvider = ({
  children
}: PropsWithChildren<{}>): ReactElement => {
  const [theme, setTheme] = useState(DefaultTheme);
  return <ThemeProvider theme={{
    ...theme,
    toggleTheme: (newTheme: FoodlesTheme): void => setTheme(newTheme)
  }} data-sentry-element="ThemeProvider" data-sentry-component="FoodlesThemeProvider" data-sentry-source-file="theming.tsx">
      {children}
    </ThemeProvider>;
};