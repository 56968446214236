'use client';

import { CloudinaryContext } from '@legacy-components/ResponsiveImage';
import config from '@legacy/core/config';
import { ReactNode } from 'react';
const CloudinaryProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  return <CloudinaryContext.Provider value={config.cloudinary} data-sentry-element="unknown" data-sentry-component="CloudinaryProvider" data-sentry-source-file="CloudinaryProvider.tsx">
      {children}
    </CloudinaryContext.Provider>;
};
export default CloudinaryProvider;